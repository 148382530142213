const swiperActus = new Swiper('#sitala-actus', {
  slidesPerView: 3,
  slideToClickedSlide: true,

  breakpoints: {
    200: {
      slidesPerView: 1,
    },
    640: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 2,
    },
    1150: {
      slidesPerView: 2,
    },
    1151: {
      slidesPerView: 3,
    },
  },

  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
});
const cardActu = document.querySelectorAll('.card-actu');

cardActu.forEach((card) => {
  const cardTitle = card.querySelector('.headings');
  const cardContent = card.querySelector('.card-actu__content');

  cardContent.style.transform = `translateY(${cardContent.offsetHeight}px)`;

  card.addEventListener('mouseenter', (e) => {
    cardTitle.style.transform = `translateY(-${cardContent.offsetHeight}px)`;
  });

  card.addEventListener('mouseleave', (e) => {
    cardTitle.style.transform = `translateY(0px)`;
  });
});
